import * as S3 from 'aws-sdk/clients/s3';

// Dev Access Key
// const accessKeyId = "AKIA47P2MEWE6PS6WJM4";
// const secretAccessKey = "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";

// Live Access Key
const accessKeyId = 'AKIASGVYJDO6RZICEYGV';
const secretAccessKey = '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';

export const bucket = new S3({
  accessKeyId,
  secretAccessKey,
  region: 'ap-southeast-1',
});
