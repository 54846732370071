import { bucket } from 'S3/upload';
import { toastAlert } from 'Api/middleware';

export default function uploadFile(props) {
  const { fileContent, apiFunction, path, applyValidate, setbtnLoading } =
    props;
  console.log({ props });
  const checkUploadImage = () => {
    if (fileContent?.bubbles) {
      const file = fileContent.target.files[0];

      const contentType = file.type;

      const uploadValidation =
        contentType === 'image/jpeg' || contentType === 'image/png';

      if (!uploadValidation && applyValidate) {
        setbtnLoading(false);
        return toastAlert(
          'info',
          'Make sure to upload correct format of image'
        );
      } else {
        if (file.size >= 5000000) {
          return toastAlert('info', 'File size is too large');
        }

        const params = {
          Bucket: `pickapps3live`,
          Key: path + file.name,
          Body: file,
          ACL: 'public-read',
          ContentType: contentType,
        };

        bucket.upload(params, (err, data) => {
          if (err) {
            setbtnLoading(false);
            return toastAlert('info', 'Failed to upload');
          }
          return apiFunction(data.Location);
        });
      }
    } else {
      return apiFunction();
    }
  };

  return checkUploadImage();
}
